import { useEffect } from "react";
import { usePermission } from "@/hooks/usePermissions";
import useSettings from "@/hooks/useSettings";
import { SettingsObject } from "@/contexts/SettingsContext";

interface UseBoardsSkipAccessCheckResponse {
  savePreference(value: boolean): void;
  skipAccessCheck: boolean;
  canConfigureSkipAccessCheck: boolean;
}

/**
 * Hook to get and save preference of boars access.
 */
export function useBoardsSkipAccessCheck(): UseBoardsSkipAccessCheckResponse {
  const { granted: canConfigureSkipAccessCheck, state } = usePermission("board:skipAccessCheck", false);
  const { settings, saveSettings } = useSettings();

  function savePreference(value: boolean) {
    saveSettings((previousState: SettingsObject) => ({ ...previousState, boardSkipAccessCheck: value }));
  }
  useEffect(() => {
    if (!canConfigureSkipAccessCheck && state === "resolved") {
      savePreference(false);
      return;
    }
  }, [state]);

  return {
    savePreference,
    skipAccessCheck: settings.boardSkipAccessCheck ?? false,
    canConfigureSkipAccessCheck,
  };
}
