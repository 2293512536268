import { useEffect, useState } from "react";
import type { FC } from "react";
import { Link as RouterLink, useLocation } from "react-router-dom";
import { Box, Drawer, SwipeableDrawer, Divider, useMediaQuery, Theme } from "@mui/material";
import { getSidebarSections } from "@/routing/SidebarSections";
import { LaraLogo } from "@/svgs/LaraLogo";
import NavSection, { NavSectionItem } from "./NavSection";
import Scrollbar from "@/components/Scrollbar";
import { SIDEBAR } from "src/theme/layout";
import useAuth from "@/hooks/useAuth";
import { BooleanTreatmentRecordObject, SplitTreatment, useBooleanTreatmentsAsObject } from "@/hooks/useSplitTreatment";
import { SidebarSections } from "@/types/sidebar";
import { usePromise } from "@/hooks/usePromise";
import { OldRoleCode } from "@/types/user";
import { managerIsBaseLine } from "@/services/teamsService";
import { compact, flatten } from "lodash";

interface DashboardSidebarProps {
  onMobileClose: () => void;
  openMobile: boolean;
  onMobileOpen: () => void;
}

const iOS = typeof navigator !== "undefined" && /iPad|iPhone|iPod/.test(navigator.userAgent);

function getTreatmentsFromSections(sections: SidebarSections): SplitTreatment[] {
  return compact(
    flatten(flatten(sections.map((section) => section.items.map((item) => item.showRules?.treatments?.values))))
  );
}

function filterSidebarSectionsByTreatments(
  items: NavSectionItem[],
  treatmentObject: BooleanTreatmentRecordObject,
  showManagerTeamComparison: boolean
): NavSectionItem[] {
  return items.filter(({ children, name, showRules }) => {
    if (children) {
      children = filterSidebarSectionsByTreatments(children, treatmentObject, showManagerTeamComparison);
    }

    if (name === MANAGER_TEAM_COMPARISON_SECTION) {
      return showManagerTeamComparison;
    }

    if (!showRules || !showRules.treatments) {
      return true;
    }

    if (showRules.treatments.values.length > 0) {
      return showRules.treatments.values.reduce((acc, current, index) => {
        const ruleValue = showRules.treatments!.rules ? showRules.treatments!.rules[index] : true;
        return acc && treatmentObject[current] === ruleValue;
      }, true);
    }

    return true;
  });
}

export const MANAGER_TEAM_COMPARISON_SECTION = "managerTeamComparison";

const DashboardSidebar: FC<DashboardSidebarProps> = (props) => {
  const { onMobileClose, onMobileOpen, openMobile } = props;
  const [collapsed, setCollapsed] = useState(true);
  const location = useLocation();
  const auth = useAuth();
  const [showManagerTeamComparison, setShowManagerTeamComparison] = useState(false);

  const shouldShowManagerTeamComparisonPromise = usePromise(async () => {
    if (!auth.jwtUser?.hasRole(OldRoleCode.MANAGER)) {
      return false;
    }
    const employeeReference = auth.user?.employeeReference ?? null;
    const isBaseLine = employeeReference && (await managerIsBaseLine(employeeReference));
    return !isBaseLine;
  });

  useEffect(() => {
    setShowManagerTeamComparison(Boolean(shouldShowManagerTeamComparisonPromise.value));
  }, [shouldShowManagerTeamComparisonPromise.status]);

  const sections = getSidebarSections(auth.jwtUser);
  const treatmentNames = getTreatmentsFromSections(sections);

  const [treatmentObject] = useBooleanTreatmentsAsObject(
    treatmentNames,
    treatmentNames.map(() => false)
  );
  const filterSections = sections.map((section) => ({
    ...section,
    items: filterSidebarSectionsByTreatments(section.items, treatmentObject, showManagerTeamComparison),
  }));

  useEffect(() => {
    if (openMobile && onMobileClose) {
      onMobileClose();
    }
  }, [location.pathname]);

  const lgUp = useMediaQuery<Theme>((theme) => theme.breakpoints.up("lg"));

  const content = (
    <Box
      onMouseEnter={() => setCollapsed(false)}
      onMouseLeave={() => setCollapsed(true)}
      sx={{
        display: "flex",
        flexDirection: "column",
        height: "100%",
      }}
    >
      <Scrollbar options={{ suppressScrollX: true }}>
        {lgUp ? null : (
          <Box
            sx={{
              display: "flex",
              justifyContent: "center",
              p: 2,
            }}
          >
            <RouterLink to="/">
              <LaraLogo sx={{ height: 40 }} />
            </RouterLink>
          </Box>
        )}
        <Box sx={{ p: 0 }}>
          {filterSections.map((section, i) => (
            <Box key={i}>
              {i !== 0 ? <Divider /> : null}
              <NavSection pathname={location.pathname} collapsed={collapsed} sx={{ pb: 0 }} {...section} />
            </Box>
          ))}
        </Box>
      </Scrollbar>
    </Box>
  );

  return lgUp ? (
    <Drawer
      anchor="left"
      open
      PaperProps={{
        sx: {
          backgroundColor: "background.paper",
          height: "calc(100% - 64px) !important",
          top: "64px !Important",
          width: collapsed ? SIDEBAR.collapsed.width : SIDEBAR.width,
          transition: (theme) =>
            theme.transitions.create(["width"], {
              easing: theme.transitions.easing.sharp,
              duration: theme.transitions.duration.enteringScreen,
            }),
        },
      }}
      variant="permanent"
    >
      {content}
    </Drawer>
  ) : (
    <SwipeableDrawer
      anchor="left"
      onClose={onMobileClose}
      onOpen={onMobileOpen}
      open={openMobile}
      disableBackdropTransition={!iOS}
      disableSwipeToOpen={true}
      PaperProps={{
        sx: {
          backgroundColor: "background.paper",
          width: SIDEBAR.width,
          backgroundImage: "none",
        },
      }}
      sx={{ zIndex: (theme) => theme.zIndex.drawer + 100 }}
      variant="temporary"
    >
      {content}
    </SwipeableDrawer>
  );
};

export default DashboardSidebar;
