import { originalOrgDashboardAxios, dashboardAxios, isModelValidationError, isAxiosError } from "@/lib/axios";
import { CustomSegmentFilter } from "@/modules/settings/components/UserSegmentFiltersConstructor";
import { Organization } from "@/types/common";
import { User } from "@/types/user";

export async function getUserMe(): Promise<User> {
  const response = await originalOrgDashboardAxios.get<User>(`/v1/users/me`);
  const user = response.data;

  user.fullName = user.firstName + " " + user.lastName;
  return user;
}

export async function updatePassword(options: { lastPassword: string; newPassword: string }): Promise<User> {
  try {
    const response = await originalOrgDashboardAxios.patch<User>(`/v1/users/me`, {
      lastPassword: options.lastPassword,
      newPassword: options.newPassword,
    });
    const user = response.data;
    return user;
  } catch (error: unknown) {
    if (isAxiosError(error)) {
      if (isModelValidationError(error, "lastPassword")) {
        throw new Error("Invalid password");
      }
    }
    throw error;
  }
}

export async function setBoardAsHome(homeBoardReference: string | null): Promise<User> {
  const response = await originalOrgDashboardAxios.patch<User>(`/v1/users/me`, {
    homeBoardReference,
  });
  const user = response.data;
  return user;
}

export async function listOrganizations(): Promise<Organization[]> {
  const response = await originalOrgDashboardAxios.get<Organization[]>(`/v1/organizations`);
  return response.data;
}

export interface SearchUsersParams {
  fields?: string[];
  role?: string[];
  s?: string;
}

export async function searchUsers(params?: SearchUsersParams): Promise<User[]> {
  const safeParams: { fields?: string; role?: string; s?: string } = {};

  if (params?.fields) {
    safeParams.fields = params.fields.join(",");
  }

  if (params?.role) {
    safeParams.role = params.role.join(",");
  }

  if (params?.s) {
    safeParams.s = params.s;
  }

  const response = await dashboardAxios.get<{ elements: User[] }>(`/v1/users`, { params: safeParams });
  return response.data.elements;
}

export async function usersWithAccessTo(
  employeeReference: string,
  params?: Pick<SearchUsersParams, "fields">
): Promise<User[]> {
  const safeParams: { fields?: string } = {};

  if (params?.fields) {
    safeParams.fields = params.fields.join(",");
  }

  const response = await dashboardAxios.get<{ elements: User[] }>(`/v1/users/with-access-to/${employeeReference}`, {
    params: safeParams,
  });
  return response.data.elements;
}

const CACHE_USER_DETAIL = new Map<string, User>();

export async function getUser(userId: string, disableCache: boolean = false): Promise<User> {
  let user = CACHE_USER_DETAIL.get(userId);

  if (!user || disableCache) {
    const response = await dashboardAxios.get<User>(`/v1/users/${userId}`);
    const userResponse = response.data;

    CACHE_USER_DETAIL.set(userId, userResponse);

    user = userResponse;
  }

  return user;
}

export async function saveUser(user: Partial<User> & { id: string }): Promise<User> {
  const response = await dashboardAxios.patch<User>(`/v1/users/${user.id}`, user);
  return response.data;
}

export interface CreateUserParams {
  firstName: string;
  lastName: string;
  email: string;
  roleId: string;
  employeeReference: string;
  segmentFilters?: CustomSegmentFilter;
  notify: boolean;
}
export async function createUser(user: CreateUserParams): Promise<User> {
  const response = await dashboardAxios.post<User>(`/v1/users`, user);
  return response.data;
}

export async function deleteUser(userId: string): Promise<User> {
  const response = await dashboardAxios.delete<User>(`/v1/users/${userId}`);
  return response.data;
}

export async function resetUserPassword(userId: string): Promise<User> {
  const response = await dashboardAxios.post<User>(`/v1/users/${userId}/reset-password`);
  return response.data;
}
