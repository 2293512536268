import type { FC } from "react";
import { Box, Divider, LinearProgress, List, Typography } from "@mui/material";
import { Employee } from "@/types/common";
import { useTranslation } from "react-i18next";
import EmployeeMetadataItem from "./EmployeeMetadataItem";
import { useDateFns } from "@/hooks/useDateFns";
import AlternateEmailRoundedIcon from "@mui/icons-material/AlternateEmailRounded";
import PhoneIphoneRoundedIcon from "@mui/icons-material/PhoneIphoneRounded";
import Grid3x3RoundedIcon from "@mui/icons-material/Grid3x3Rounded";
import LocationOnRoundedIcon from "@mui/icons-material/LocationOnRounded";
import ArrowForwardIcon from "@mui/icons-material/ArrowForward";
import isFormerEmployee from "@/utils/isFormerEmployee";
import { Link } from "react-router-dom";
import { take } from "lodash";
import { mapIsoToCountryName } from "@/utils/country";
import { laraScoreColor } from "@/theme/laraScoreColor";
import { hexToRGB } from "@/utils/colors";
import { createDateWithoutCurrentTimeZone } from "@/utils/date";
import LabelImportant from "@mui/icons-material/LabelImportant";
import { useEmployeeFields } from "@/hooks/useEmployeeFields";

const SKIP_FIELD_CODE_IN_EMPLOYEE_METADATA_ITEMS = [
  "jobTitle", // Skip job title because it is already displayed in the employee card
];

export interface EmployeeMetadataProps {
  employee: Employee;
}

const EmployeeMetadata: FC<EmployeeMetadataProps> = (props) => {
  const { employee } = props;
  const { t } = useTranslation();
  const { formatDistanceToNowStrict, shortDate, formatDistanceStrict } = useDateFns();

  const { fields } = useEmployeeFields();

  const pmList = employee.managers.filter((m) => m.principal);

  const principalManager = pmList.length > 0 ? pmList[0] : null;

  const directReports = take(employee.directReports, 3);
  const engagementScoreColor = employee.engagementScore ? laraScoreColor(employee.engagementScore) : null;

  const formerEmployee = isFormerEmployee(employee.leavingDate);

  const employeeMetadataFields = fields.filter((f) => f.options.visibility.showInEmployeeDetail);

  return (
    <Box
      sx={{
        color: "text.primary",
        backgroundColor: "background.paper",
        height: "100%",
        px: 2,
        py: 3,
        overflow: "hidden",
      }}
    >
      {employee.engagementScore && (
        <>
          <List>
            <Typography sx={{ mb: 2 }} variant="h6">
              {t("employee.detail.layout.metadata.engagementScore")} ({employee.engagementScore})
            </Typography>
            <LinearProgress
              variant="determinate"
              value={employee.engagementScore}
              sx={{
                "& .MuiLinearProgress-bar1Determinate": {
                  backgroundColor: engagementScoreColor,
                },
                "&.MuiLinearProgress-root": {
                  backgroundColor: engagementScoreColor ? hexToRGB(engagementScoreColor, 0.25) : "gray",
                },
              }}
            />
          </List>
          <Divider sx={{ my: 2 }} />
        </>
      )}
      <List>
        <Typography variant="h6">{t("employee.detail.layout.metadata.contactInformation")}</Typography>
        <EmployeeMetadataItem icon={PhoneIphoneRoundedIcon} primary={employee.phone} />
        <EmployeeMetadataItem icon={AlternateEmailRoundedIcon} primary={employee.email} />
      </List>
      <Divider sx={{ my: 2 }} />
      <List>
        <Typography variant="h6">{t("employee.detail.layout.metadata.hiredAt")}</Typography>
        <EmployeeMetadataItem
          primary={shortDate(createDateWithoutCurrentTimeZone(employee.startedWorkingDate))}
          secondary={!formerEmployee && formatDistanceToNowStrict(employee.startedWorkingDate)}
        />
      </List>
      {formerEmployee && (
        <List>
          <Typography variant="h6">{t("employee.detail.layout.metadata.resignationDate")}</Typography>
          <EmployeeMetadataItem
            primary={shortDate(createDateWithoutCurrentTimeZone(employee.leavingDate!))}
            secondary={`${t("employee.detail.layout.metadata.workedFor")} ${formatDistanceStrict(
              employee.startedWorkingDate,
              employee.leavingDate!
            )}`}
          />
        </List>
      )}
      <Divider sx={{ my: 2 }} />
      <List>
        <EmployeeMetadataItem
          icon={Grid3x3RoundedIcon}
          primary={employee.hrmsReference}
          secondary={t("employee.detail.layout.metadata.hrmsReference")}
        />
        <EmployeeMetadataItem
          icon={LocationOnRoundedIcon}
          primary={
            employee.city
              ? employee.country
                ? `${mapIsoToCountryName(employee.country)}, ${employee.city}`
                : null
              : mapIsoToCountryName(employee.country)
          }
        />

        {employeeMetadataFields.map((field) =>
          employee[field.fieldCode] && !SKIP_FIELD_CODE_IN_EMPLOYEE_METADATA_ITEMS.includes(field.fieldCode) ? (
            <EmployeeMetadataItem
              icon={LabelImportant}
              key={field.fieldId}
              primary={employee[field.fieldCode]}
              secondary={field.translation}
            />
          ) : null
        )}
      </List>
      <Divider sx={{ my: 2 }} />
      <List>
        <Typography variant="h6">{t("employee.detail.layout.metadata.principalManager")}</Typography>
        {principalManager ? (
          <EmployeeMetadataItem
            avatar={{
              name: principalManager.employee.name,
              src: principalManager.employee.avatar,
            }}
            primary={
              <Box>
                {principalManager.employee.name}
                <ArrowForwardIcon sx={{ ml: 0.1, fontSize: "13px" }} />
              </Box>
            }
            secondary={principalManager.employee.jobTitle}
            link={`/employees/${principalManager.employee.id}`}
          />
        ) : (
          <Typography variant="body2">{t("employee.detail.layout.metadata.noManager")}</Typography>
        )}

        {employee.managers.length > 1 ? (
          <Typography color="primary" component={Link} to={`/employees/${employee.id}/org`} variant="body2">
            {t("employee.detail.layout.metadata.seeOtherManagers", { count: employee.managers.length - 1 })}
          </Typography>
        ) : null}
      </List>
      <Divider sx={{ my: 2 }} />
      <List>
        <Typography variant="h6">{t("employee.detail.layout.metadata.directReports")}</Typography>
        {directReports.map((r) => (
          <EmployeeMetadataItem
            key={r.id}
            avatar={{
              name: r.name,
              src: r.avatar,
            }}
            primary={
              <Box>
                {r.name}
                <ArrowForwardIcon sx={{ ml: 0.1, fontSize: "13px" }} />
              </Box>
            }
            secondary={r.jobTitle}
            link={`/employees/${r.id}`}
          />
        ))}

        {employee.directReports.length > 3 ? (
          <Typography color="primary" component={Link} to={`/employees/${employee.id}/org`} variant="body2">
            {t("employee.detail.layout.metadata.seeOtherDirectReports", { count: employee.directReports.length - 3 })}
          </Typography>
        ) : null}

        {employee.directReports.length === 0 ? (
          <Typography variant="body2">{t("employee.detail.layout.metadata.noDirectReports")}</Typography>
        ) : null}
      </List>
    </Box>
  );
};

export default EmployeeMetadata;
