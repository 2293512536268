import { SplitContext, useTreatments } from "@splitsoftware/splitio-react";
import { isArray } from "lodash";
import { useContext } from "react";

export enum SplitTreatment {
  TEXT_DASHBOARD = "text_dashboard",
  EDIT_TEAMS = "edit_teams",
  CREATE_TEAMS = "create_teams",
  CREATE_MANAGERS = "create_managers",
  CASE_LIST_GROUPED_BY_EMPLOYEE = "case_list_grouped_by_employee",
  MANAGERS_CAN_CREATE_TEAMS = "managers_can_create_teams",
  FAQS_ACTIVATED = "faqs_activated",
  OFFBOARDING_CONTACT_ENABLED = "offboarding_contact_enabled",
  AUTOMATIC_OFFBOARDING_DELIVERY_ACTIVATED = "automatic_offboarding_delivery_activated",
  EXPERIENCE_MODULE_ACTIVATED = "experience_module_activated",
  FEEDBACK_ENABLED = "feedback_enabled",
  CUSTOM_DASHBOARD = "custom_dashboard",
  CAN_FILTER_BY_ONE_TIME_QUESTIONS = "can_filter_by_one_time_questions",
  HIDE_SECTION_OF_HELPDESK_DASHBOARD = "hide_section_of_helpdesk_dashboard",
  GLOBAL_FILTERS_FOR_BOARDS = "global_filters_for_boards",
  SHOW_BOARD_SHARE_BUTTON = "show_board_share_button",
  FEEDBACK_AUTO_TAG = "feedback_auto_tag",
  APP_REPORT_SECTION = "app_report_section",
}

export const SPLIT_SDK_LOADING = "splitSdkLoading";
export const SPLIT_SDK_ERROR = "splitSdkError";

export const SPLIT_ON = "on";
export const SPLIT_OFF = "off";

export enum SplitTreatmentState {
  READY = "ready",
  LOADING = "loading",
  ERROR = "error",
}

function useSplitTreatment<T>(
  treatmentNames: SplitTreatment | SplitTreatment[],
  defaultValue: T
): [SplitIO.TreatmentsWithConfig | T, SplitTreatmentState] {
  const { isReady, isTimedout } = useContext(SplitContext);
  const treatment = useTreatments(isArray(treatmentNames) ? treatmentNames : [treatmentNames]);

  if (isTimedout) {
    console.error("Error loading Split SDK");
    return [defaultValue, SplitTreatmentState.ERROR];
  }

  const readyStatus = isReady ? SplitTreatmentState.READY : SplitTreatmentState.LOADING;

  return [isReady ? treatment : defaultValue, readyStatus];
}

export function useBooleanTreatment(
  treatmentName: SplitTreatment,
  defaultValue = false
): [boolean, SplitTreatmentState] {
  const [treatmentSplit, readyStatus] = useSplitTreatment<boolean>(treatmentName, defaultValue);

  if (treatmentSplit[treatmentName]) {
    const { treatment } = treatmentSplit[treatmentName];

    return [treatment === SPLIT_ON, readyStatus];
  }

  return [defaultValue, readyStatus];
}

export function useBooleanTreatments(
  treatmentNames: SplitTreatment[],
  defaultValue: boolean[]
): [boolean[], SplitTreatmentState] {
  const [treatmentsSplit, readyStatus] = useSplitTreatment<boolean[]>(treatmentNames, defaultValue);

  const allTreatmentValues = treatmentNames
    .filter((treatmentName) => treatmentsSplit[treatmentName] !== undefined)
    .map((treatmentName) => treatmentsSplit[treatmentName].treatment === SPLIT_ON);

  if (allTreatmentValues.length === treatmentNames.length) {
    return [allTreatmentValues, readyStatus];
  }

  return [defaultValue, readyStatus];
}

export type BooleanTreatmentRecordObject = Record<SplitTreatment, boolean>;

export function useBooleanTreatmentsAsObject(
  treatmentNames: SplitTreatment[],
  defaultValue: boolean[]
): [BooleanTreatmentRecordObject, SplitTreatmentState] {
  const [treatmentsSplit, readyStatus] = useBooleanTreatments(treatmentNames, defaultValue);

  const object = treatmentsSplit.reduce((acc, current, index) => {
    acc[treatmentNames[index]] = current;
    return acc;
  }, {} as BooleanTreatmentRecordObject);

  return [object, readyStatus];
}
